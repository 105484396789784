<script setup lang="ts">
import { matClose, matMenu } from '@quasar/extras/material-icons'
import jpoolLogoDark from '~/assets/img/logo/jpool-logo-dark.svg'
import { PriorityFee } from '~/features/priority-fee'

const route = useRoute()
const isHoldersClub = computed(() => route.path.includes('holders-club'))

const visible = ref(false)

function handleClick(e: any) {
  const isOutsideClick = e?.srcElement?.id === 'mobile-menu-wrapper'
  if (isOutsideClick) {
    closeMenu()
  }
}

function closeMenu() {
  visible.value = false
}
</script>

<template>
  <q-btn v-bind="$attrs" dense unelevated class="q-pa-sm mobile-menu-btn" color="secondary" text-color="black" @click="visible = true">
    <q-icon size="38px" :name="matMenu" color="black" />
  </q-btn>
  <q-icon
    :name="matClose" class="app-sidebar__close" :class="{ 'app-sidebar__close--active': visible }"
    @click="visible = false"
  />
  <div
    id="mobile-menu-wrapper" class="app-sidebar-wrapper" :class="{ 'app-sidebar-wrapper--active': visible }"
    @click="handleClick"
  >
    <div class="app-sidebar mobile-menu">
      <div class="full-width">
        <div class="row justify-end q-my-xs  q-gutter-sm">
          <jpoints-reward-info />
          <priority-fee />
          <select-language />
        </div>
        <holders-club-navigation-tabs v-if="isHoldersClub" @close-menu="closeMenu" />
        <navigation-tabs v-else no-more-item @close-menu="closeMenu" />
        <holders-club-logo v-if="!isHoldersClub" dark-icon :blank="false" custom-class="mobile-menu--project-nav" @click="closeMenu" />
        <router-link v-else to="/" class="mobile-menu--project-nav" @click="closeMenu">
          <img :src="jpoolLogoDark" alt="jpool logo">
        </router-link>
      </div>
    </div>
  </div>
</template>
