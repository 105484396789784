<script setup lang="ts">
import { useWallet } from 'solana-wallets-vue'
import { shortenAddress } from '~/utils'

const wallet = useWallet()
const { connected } = wallet
const walletAddress = computed(() => wallet.publicKey.value?.toBase58() ?? '')
const walletShortAddress = computed(() => shortenAddress(walletAddress.value, 14))
const walletShortAddressMob = computed(() => shortenAddress(walletAddress.value, 11))
</script>

<template>
  <div v-if="connected" class="wallet-address">
    <div class="wallet-address__label">
      {{ $t('common.address') }}
    </div>
    <div class="wallet-address__value">
      <span class="wallet-address__address">{{ $q.screen.gt.xs ? walletShortAddress : walletShortAddressMob }}</span>
      <copy-to-clipboard anchor="top left" self="bottom left" class="wallet-address__copy-btn" :text="walletAddress" />
    </div>
  </div>
</template>
