<script lang="ts">
import { useWallet } from 'solana-wallets-vue'
import { ENDPOINTS } from '~/config'
import type { Endpoint } from '~/store'

export default defineComponent({
  setup() {
    const connectionStore = useConnectionStore()
    const { connected, connect, disconnect, autoConnect } = useWallet()
    return {
      groups: [
        ENDPOINTS.filter(e => e.cluster === 'mainnet-beta'),
        ENDPOINTS.filter(e => e.cluster !== 'mainnet-beta'),
      ],
      endpoint: computed(() => connectionStore.endpoint),
      select: (e: Endpoint) => {
        if (connected.value && connectionStore.cluster !== e.cluster) {
          disconnect()
          if (autoConnect.value) {
            connect()
          }
        }
        connectionStore.setRpc(e.id)
      },
    }
  },
})
</script>

<template>
  <q-btn-dropdown
    class="cluster-selector"
    text-color="white"
    :label="endpoint.name"
    :model-value="false"
    auto-close
    unelevated
    :ripple="false"
  >
    <q-list>
      <template v-for="(items, index) in groups" :key="`${index}-cluster-group`">
        <q-item v-for="item in items" :key="item.id" clickable @click="select(item)">
          <q-item-section>
            <q-item-label>
              <b>{{ item.name }}</b>
            </q-item-label>
            <span class="text-blue-grey">{{ item.url.substring(0, 30) }}</span>
          </q-item-section>
        </q-item>
        <q-separator v-if="index !== groups.length - 1" />
      </template>
    </q-list>
  </q-btn-dropdown>
</template>
