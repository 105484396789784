<script lang="ts" setup>
defineProps({
  noMoreItem: Boolean,
})

const emit = defineEmits(['closeMenu'])

const { t } = useTranslation()

export type Route = {
  name: string
  isLink?: boolean
  route: string
  // for submenu
  newSection?: boolean
  icon?: string
}

  type NavItem = {
    name: string
    isLink?: boolean
    isDisabled?: boolean
    route?: string
    routes?: Route[]
  }

const routes = computed<NavItem[]>(() => {
  return [
    {
      route: '/holders-club/rewards',
      name: t('hc.tabs.rewards'),
    },
    {
      route: '/holders-club/privileges',
      name: t('hc.tabs.privileges'),
    },
    {
      route: '/holders-club/about',
      name: t('hc.tabs.about'),
    },
  ]
})

function closeMenu() {
  emit('closeMenu')
}
</script>

<template>
  <div class="navigation-tabs">
    <template v-for="navItem in routes" :key="navItem.name">
      <navigation-link :page="(navItem as Route)" :class="$style.tabs" @close-menu="closeMenu" />
    </template>
  </div>
</template>

<style lang="scss" module>
.tabs {
  max-width: 170px;

  @media (max-width: $breakpoint-md) {
    max-width: 100%;

    & > span {
      border-left: none !important;
    }
  }
}
</style>
