<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { useRoiCalculator } from '~/features/roi-calculator'
import { isInvalidTime } from '~/utils'

export default defineComponent({
  setup() {
    const { investmentTime, investmentPeriod } = useRoiCalculator()

    const { t } = useTranslation()

    function localesPeriod(period: string) {
      switch (period) {
        case 'Year': return t('roi.period.year')
        case 'Month': return t('roi.period.month')
        case 'Epoch': return t('roi.period.epoch')
      }
    }

    watch(investmentTime, (val) => {
      if (val && Number(val) < 0) {
        investmentTime.value = '0'
      }
    })
    return {
      localesPeriod,
      investmentTime,
      investmentPeriod,
      isInvalid: computed(
        () =>
          investmentTime.value
          && (isInvalidTime(String(investmentTime.value), investmentPeriod.value)
            || Number(investmentTime.value) < 0),
      ),
    }
  },
  data() {
    return {
      periods: ['Year', 'Month', 'Epoch'],
    }
  },
})
</script>

<template>
  <div class="sol-calculator__form__group">
    <div class="sol-calculator__form__label">
      {{ $t('roi.investmentTenure') }}
    </div>
    <div class="sol-calculator__form__input-block">
      <input
        v-model="investmentTime"
        type="number"
        class="sol-calculator__form__input"
        :class="{ 'sol-calculator__form__input--invalid': isInvalid }"
      >
      <select v-model="investmentPeriod" class="sol-calculator__form__input" name="period">
        <option v-for="period in periods" :key="period" :value="period">
          {{ localesPeriod(period) }}
        </option>
      </select>
    </div>
  </div>
</template>
