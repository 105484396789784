<script lang="ts" setup>
import { useWallet } from 'solana-wallets-vue'
import logo from '~/assets/img/logo/jpool-logo.svg'
import logoDark from '~/assets/img/logo/jpool-logo-dark.svg'
import { PriorityFee } from '~/features/priority-fee'

const route = useRoute()
const membershipStore = useMembershipStore()

const { publicKey } = useWallet()

const isHoldersClub = computed(() => route.path?.includes('holders-club'))
const isSidebar = computed(() => publicKey.value || !isHoldersClub.value)
const logoImg = computed(() => isDark.value ? logoDark : logo)
const logoTitle = computed(() => isHoldersClub.value ? 'holders club' : 'liquid staking')
</script>

<template>
  <div class="app-header row items-center">
    <network-performance />
    <offline-notice />
    <div class="container">
      <div class="app-header__wrapper row">
        <a v-if="!isHoldersClub" href="https://jpool.one/" target="_self" class="app-header__logo">
          <img alt="JPool" :src="logoImg">
          <span data-name="logo-title">{{ logoTitle }}</span>
        </a>
        <router-link v-else to="/" class="app-header__logo">
          <img alt="JPool" :src="logoImg">
          <span data-name="logo-title">{{ logoTitle }}</span>
        </router-link>

        <div class="col-md col-xs-12 app-header__center q-mt-auto q-ml-auto q-px-lg">
          <div class="row col-xs-12 justify-end q-mb-xs">
            <jpoints-reward-info v-if="!isHoldersClub && membershipStore.isHoldersClubMember" />
            <priority-fee class="q-mr-md" />
            <select-language v-if="!isHoldersClub" />
          </div>
          <holders-club-navigation-tabs v-if="isHoldersClub" />
          <navigation-tabs v-else />
        </div>

        <div class="app-header__right">
          <login-button v-if="isHoldersClub" />
          <connect-wallet v-else class="app-header__wallet-btn gt-sm" />
          <mobile-menu />
          <!-- <q-btn v-if="publicKey" dense unelevated class="app-header__sidebar-btn">
              <img :src="sidebarIcon" alt="sidebar icon" />
            </q-btn> -->
          <app-sidebar v-if="isSidebar" />
        </div>
      </div>
    </div>
  </div>
</template>
