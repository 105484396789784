<script lang="ts" setup>
import { computed } from 'vue'
import { useApyStore } from '~/store'
import { formatPct } from '~/utils'

const apyStore = useApyStore()
const apy = computed(() => formatPct.format(apyStore.apy.staking + apyStore.apy.jito))
</script>

<template>
  <div class="sol-calculator__form__group">
    <div class="sol-calculator__form__text">
      {{ $t('roi.rate') }}
    </div>
    <div class="sol-calculator__form__bigtext">
      ≈{{ apy }}
    </div>
  </div>
</template>
